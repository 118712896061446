import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { trackingInstance } from '../modules/1pt-tracking-instance';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.DEPLOY_ENV,
    integrations: [new Sentry.BrowserTracing(), new HttpClientIntegration()],
    sendDefaultPii: true,
    tracesSampleRate: process.env.DEPLOY_ENV === 'prod' ? 0.01 : 1.0,
    // Ignore errors that come from places that are not Own Up.
    allowUrls: [/https?:\/\/(\S*\.)?ownup\.com/],
    // Ignore errors related to cache invalidation.
    ignoreErrors: [/Loading chunk \d+ failed/]
  });
  if (trackingInstance.getAnonymousId()) {
    Sentry.setUser({ id: trackingInstance.getAnonymousId() });
  }
}
