const isErrorWithName = (err: unknown): err is object & { name: string } =>
  typeof err === 'object' && err !== null && 'name' in err;

// tslint:disable
try {
  // start with a simple feature detection script that
  // will throw a syntax error that can be caught
  // if the rest of the page will fail.
  Function(`
  'use strict';
  let a = 3;
  const b = 4;
`)();
} catch (err) {
  // if the eval threw a syntax error go to
  // the unsupported page
  if (isErrorWithName(err) && err.name === 'SyntaxError') {
    window.location.href = '/unsupported.html';
  }
}
